<template>
  <div class="bg-gray-light text-center pt-20 pb-20">
    <div class="max-w-8xl m-auto">
      <h3 class="text-4xl font-semibold">How it works</h3>

      <nuxt-img
        class="hidden lg:block w-3/4 mt-20 m-auto"
        alt="Navigation"
        src="/images/pages/home/navigation.svg"
        loading="lazy"
      />

      <div
        class="flex w-10/12 lg:w-full justify-center items-center mt-10"
      >
        <div class="w-1/2 lg:hidden">
          <nuxt-img
            class="m-auto"
            height="876px"
            alt="Navigation"
            src="/images/pages/home/navigation-column.svg"
            loading="lazy"
          />
        </div>
        <div
          class="w-1/2 lg:flex lg:w-10/12 m-auto space-y-6 lg:space-y-0 lg:space-x-6"
        >
          <div
            class="m-auto lg:mt-0 lg:w-1/4 h-[255px] flex flex-col justify-between border rounded-lg p-6 border-[#e6e8ef] shadow-[0px_0px_10px_#e6e8ef]"
            v-for="step in steps"
          >
            <nuxt-img
              width="120px"
              class="m-auto"
              alt="Navigation"
              :src="step.img"
            />
            <p class="text-gray text-xs mt-10">{{ step.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const steps = [
  {
    img: "/images/icons/home/step-1.svg",
    title: "Enter your postcode",
  },
  {
    img: "/images/icons/home/step-2.svg",
    title: "Choose the equipment you need",
  },
  {
    img: "/images/icons/home/step-3.svg",
    title: "Choose the dates for how long you need the equipment",
  },
  { img: "/images/icons/home/step-4.svg", title: "Checkout" },
];
</script>
